<template>
		<div v-bind:class="cartClass" >
			<div class=" noborder divcenter">
				<div class=" p-4 shoppingcart-container">
					<h3 class="py-3.5">{{ $t('aanmelding.Uw aanmelding') }}</h3>
					<ShoppingCart ></ShoppingCart>
					<MandatoryCheckbox :lang="lang" ></MandatoryCheckbox>
					<div class="flex flex-wrap" >
              <button
                  type="button"
                  v-on:click="volgende_aanmelding"
                  class="mr-2 inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  data-te-modal-dismiss
                  data-te-ripple-init
                  data-te-ripple-color="light">
                {{ $t('aanmelding.Volgende aanmelding') }}
              </button>
              <button
                  type="button"
                  v-on:click="afronden"
                  class="inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  data-te-modal-dismiss
                  data-te-ripple-init
                  data-te-ripple-color="light">
                {{ $t('aanmelding.Afronden') }}
              </button>
					</div>
				</div>
			</div>
		</div>
</template>

<script>
	import ShoppingCart from '../shoppingcart_vsc/ShoppingCartComponent';
	import AanmeldingModalMethods from '../../methods/AanmeldingModalMethods';
  import AanmeldingButtonsMethods from '../../methods/AanmeldingButtonsMethods';
	import MandatoryCheckbox from '../registrationform_vsc/MandatoryCheckboxComponent';
  import language from '../../methods/lang';
  import {SEMICOLON} from "../../semicolon_vanilla";
	export default {
        mounted() {
            console.log('ShoppingcartContainer mounted.');
        },
        data() {
            let store = this.$store;
            return {
              lang: store.getters.getLang,
              cartClass: 'list-group-item',
              error_messages: [],
              success_messages: [],
            };
        },
        methods: {
        	volgende_aanmelding: function(){
        		let currentObj = this;
        		let store = this.$store;
                this.handleSelects();
                if(AanmeldingButtonsMethods.validate(this)){
                    store.dispatch('fillBill');
                    store.dispatch('addSession',{	symposium_id:store.getters.getAanmelding.aanmelding.symposium_id,
                    								aanmelding_id:store.getters.getAanmelding.aanmelding.id
                    							});
            		currentObj.$parent.$router.push({ path: '/login_ajax_aanmelding', query: { symposium_id: store.getters.getAanmelding.aanmelding.symposium_id }});
                }
        	},
        	afronden: function(){
        		let currentObj = this;
        		let store = this.$store;
                this.handleSelects();
                if(AanmeldingButtonsMethods.validate(this)){
                    store.dispatch('fillBill');
                    currentObj.$parent.$router.push({ path: '/registration_confirm', query: { symposium_id: store.getters.getAanmelding.aanmelding.symposium_id }});
                }
        	},
            notifyError: function(msg){
              this.$parent.notifyError(msg);
            },
            handleSelects: function(){
                this.$root.$emit('handle_select');
            },
            addError: function(e) {
              this.$parent.addError(e);
            },
            clearErrors: function(){
              this.$parent.clearErrors();
            },
            addSuccessMessage: function(msg){
              this.$parent.addSuccessMessage(msg);
            },
            clearSuccessMessages: function(){
              this.$parent.clearSuccessMessages();
            },
            clearMessages: function(){
              this.$parent.clearSuccessMessages();
            }
        },
        components: {
            ShoppingCart,
            MandatoryCheckbox
        },
        computed: {
              elementResult: function(){
                return $('#form-result-registration');
              }
        },
		created() {
		    this.$store.watch(
		      (state, getters) => getters.getAanmeldOpties.models,
		      () => {
		        //this.items = this.$store.getters.getAanmeldOpties.models;
		      },
		    );
  		}
    }
</script>
