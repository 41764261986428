<template>
  <div class=" ">
      <div class="grid grid-cols-12 shoppingcart_item_row" >
        <div class="col-span-11 sm:col-span-8 label_div shoppingcart_div" ></div>
        <div class="col-span-1 hidden sm:inline-block  shoppingcart_div">{{ $t('aanmelding.aantal') }}</div>
        <div class="col-span-2 hidden sm:inline-block  shoppingcart_div">{{ $t('aanmelding.stuks prijs') }}</div>
        <div class="col-span-1 shoppingcart_div col-prijs">{{ $t('aanmelding.Totaal') }}</div>
    </div>
		<BillSession v-for="session in sessions"  :session="session" :lang="lang"  :key="session.id"></BillSession>
    <BillTotal ></BillTotal>
	</div>
</template>

<script>
	import BillSession from './BillSessionComponent';
    import BillTotal from './BillTotalComponent';
    export default {
        mounted() {
            console.log('Shopping Cart mounted.');
        },
        data() {
            let store = this.$store;
            return {
              sessions: store.getters.getSessions.models,
              lang: store.getters.getLang
            };
        },
        components: {
        	BillSession,
            BillTotal
        },
        created() {
            this.$store.watch(
              (state, getters) => getters.getSessions.models,
              () => {
                this.sessions = this.$store.getters.getSessions.models;
              },
            );
        }

    }
</script>
