<template>
	<div class="grid grid-cols-12 shoppingcart_item_row py-1" >
			<div class="col-span-8 label_div shoppingcart_div px-3.5" v-html=titel></div>
            <div class="col-span-1 shoppingcart_div">{{ aantal }}</div>
			<div class="col-span-2 shoppingcart_div">€ {{ waarde }}</div>
			<div class="col-span-1 shoppingcart_div">
				<div class="remove text-right" v-on:click="removeItem">
					<i class="mdi mdi-close-box text-project-red" ></i>
				</div>
			</div>
		</div>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {
        mounted() {
            console.log('Shopping Cart Item vsc mounted.');
        },
        props: ['item'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	},
            aantal: function(){
                if(this.item.aantal>1){
                    return this.item.aantal;
                }
                return 1;
            },
            waarde: function(){
                if(this.item.value==='0'||!this.item.value){
                    return 0;
                }
                return this.item.value.replace(',-','');
            }
        },
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	}
        }
    }
</script>
