import { Model } from 'vue-mc'
import { required } from 'vue-mc/validation'

export default class AanmeldOptie extends Model {

    defaults() {
        return {
            id : '',
            symposium_id : '',
            naam : '',
            naam_en : '',
            soort : '',
            zichtbaar : '',
            toelichting : '',
            toelichting_en : '',
            opties : '',
            volgnummer : '',
            unn_no_id : '',
            datum : '',
            factuur_regel : '',
            volgeboekt : '',
        }
    }

    options() {
        return {
            useFirstErrorOnly: true,
        }
    }

    validation() {
        return ;
    }

    mutations() {
        return ;
    }

    routes() {
        return {
            
        }
    }

}