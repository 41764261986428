<template>

	    <div class="grid grid-cols-12" v-bind:for="item.id" >

	    	<div class="col-span-8" v-bind:id="item.id">
	    		<el-checkbox v-if="item.volgeboekt == 0" v-model="checkedValue" v-on:click="clickCheckbox"  v-bind:name="item.id.toString()">
	    			<span  v-on:click="clickCheckbox" v-html="naamTranslated"></span>
	    			<Toelichting :item="item"></Toelichting>
	    		</el-checkbox>
	    		<div v-else >
	    			<span  v-html="naamTranslated"></span>
	    			<Toelichting :item="item"></Toelichting>
	    		</div>
	    	</div>
	    	<div class="col-span-4 justify-between" >
	    		<div class="text-right">
	    			<Counter  :item="item" :key="item.id+'counter'"  />
	    			<label v-if="item.volgeboekt == 0" v-on:click="clickCheckbox" >
	    				€ {{ item.opties }}
	    			</label>
	    			<span v-else class="optie_volgeboekt">{{ $t('aanmelding.volgeboekt') }}</span>
	    		</div>

	    	</div>
	    </div>

</template>

<script>
	import language from '../../methods/lang';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	import Counter from './CounterComponent';
	import Toelichting from './ToelichtingComponent';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('prijscomponent mounted.');
            let store = this.$store;
            this.setChecked();
            this.spanClickEvent();
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated
        },
        methods: {
        	clickCheckbox: function(event){
        		event.preventDefault();
        		if(this.checkedValue){
        			return aanmeldingEntryMethods.deleteAanmeldingEntry(this);
        		}
        		aanmeldingEntryMethods.createAanmeldingEntry(this,this.item.opties);
        	},
        	setChecked: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.checkedValue = true;
        			return;
        		}
        		this.checkedValue = false;
                this.spanClickEvent();
        	},
        	isChecked: function(){
        		return this.checkedValue;
        	},
            spanClickEvent: function(){
                let currentObj = this;
                //prevent default checkbox behaviour
                $('div#'+this.item.id).off();
                $('div#'+this.item.id).on('click','.el-checkbox__input',function(event){
                    currentObj.clickCheckbox(event);
                })
            }

        },
        data() {
	      return {
	      	aanmeldingEntry: undefined,
	      	checkedValue: false
	      }
	    },
		created() {
            let currentObj = this;
		    this.$store.watch(
		      (state, getters) => getters.getAanmeldEntries.models,
		      () => {
                  this.setChecked();
		      },
		    );
		    this.$store.watch(
		      (state, getters) => getters.getCurrentSessieId,
		      () => {
		      	this.setChecked();
		      }
		    );
  		},
  		components: {
  			Counter,
  			Toelichting
  		}
    }
</script>
