<template>
    
</template>

<script>
    
    export default {
        mounted() {
            console.log('init store');
            let store = this.$store;
            store.dispatch('setKlantId',{klant_id:this.klant_id});
            store.dispatch('preAanmelding',{symposium_id:this.symposium_id});
            store.dispatch('setParticipants',this.participants);
            this.$i18n.setLocaleMessage('nl', this.lang_json.nl);
            this.$i18n.setLocaleMessage('en', this.lang_json.en);
            this.$i18n.locale = this.locale;
            this.lang = this.locale;
        },
        props:['symposium_id','klant_id','participants','lang_json','locale']
    }
</script>