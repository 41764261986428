<template>
    <div class="container">

      <div
          data-te-modal-init
          class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
          id="AanmeldingParticipantsModal"
          tabindex="-1"
          aria-labelledby="AanmeldingParticipantsModalCenterTitle"
          aria-modal="true"
          role="dialog">
        <div
            data-te-modal-dialog-ref
            class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
          <div
              class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div
                class="h-72 flex flex-shrink-0 items-start justify-end content-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 bg-bg-registration-deelnemer
                 bg-center bg-no-repeat">
              <ErrorToast :error_messages="error_messages"></ErrorToast>
              <!--Close button-->
              <button
                  @click="closeModal"
                  type="button"
                  class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    class="h-6 w-6">
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

              <!--Modal title-->

            </div>

            <!--Modal body-->

            <div class="relative p-4">
                <div v-if="hideCreateSkeletonUserForm" class="card-body p-4">
                  <form class="w-full max-w-lg" @submit="formSubmit" id="participants_form">
                    <div class="flex justify-start -mx-3 mb-6">
                      <h3
                          class="leading-normal text-neutral-800 dark:text-neutral-200"
                          id="exampleModalScrollableLabel">
                        {{ $t('aanmelding.Kies uw deelnemer') }}
                      </h3>
                    </div>
                    <Participant v-for="participant in items.models"
                                 :key="participant.id"
                                 :participant="participant">

                    </Participant>
                    <div class="flex items-center justify-between">
                      <button class="bg-registration-button text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" name="modal-feedback-submit">
                        {{ $t('aanmelding.Verstuur') }}
                      </button>
                      <a class="inline-block align-baseline font-bold text-sm text-project-default hover:text-blue-800" id="createSkeletUserButton" @click="showCreateSkeletonUser" href="#" >
                        {{ $t('aanmelding.Nieuwe medewerker') }}
                      </a>
                    </div>
                  </form>
                </div>
                <div v-if="showCreateSkeletonUserForm" class="card-body p-4">
                  <h3 class="card-title font-body">Login</h3>
                  <div >
                    <form class="w-full group " @submit="createSkeletonUserSubmit" novalidate>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3 mb-6 md:mb-0"
                             :data-msg-required="salutation_required"
                             semicolon-radio-required
                        >
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="geslacht">
                            {{ $t('mijn_gegevens.Aanhef') }} *
                          </label>
                          <div class="grid rounded h-[46px]  grid-cols-2 gap-2  bg-gray-200 ">
                            <div class="">
                              <input type="radio" v-model="geslacht" id="1" value="man" class="peer hidden"
                              />
                              <label for="1" class="rounded h-full flex justify-center items-center cursor-pointer select-none  p-0 text-center hover:bg-project-default hover:text-white peer-checked:bg-project-default peer-checked:font-bold peer-checked:text-white">{{ $t('mijn_gegevens.De heer') }}</label>
                            </div>

                            <div class="">
                              <input type="radio" v-model="geslacht" id="2" value="vrouw" class="peer hidden"
                              />
                              <label for="2" class="rounded h-full flex justify-center items-center align-middle cursor-pointer select-none  p-0 text-center hover:bg-project-default hover:text-white peer-checked:bg-project-default peer-checked:font-bold peer-checked:text-white">{{ $t('mijn_gegevens.Mevrouw') }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full  px-3 mb-6 md:mb-0">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="mijngegevens-form-voorletters">
                            {{ $t('mijn_gegevens.Voorletters') }} *
                          </label>
                          <input class="invalid:[&:not(:focus)]:border-red-500 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="mijngegevens-form-voorletters"
                                 :placeholder="$t('mijn_gegevens.Voorletters')"
                                 v-model="voorletters"
                                 type="text"
                                 :data-msg-required="initials_required"
                                 semicolon-required>
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full  px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="mijngegevens-form-voornaam">
                            {{ $t('mijn_gegevens.Voornaam') }} *
                          </label>
                          <input class="invalid:[&:not(:focus)]:border-red-500 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200  rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-project-default" id="mijngegevens-form-voornaam"
                                 :placeholder="$t('mijn_gegevens.Voornaam')"
                                 v-model="voornaam"
                                 type="text"
                                 :data-msg-required="firstname_required"
                                 semicolon-required
                          >
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="mijngegevens-form-naam">
                            {{ $t('mijn_gegevens.Tussenvoegsel') }}
                          </label>
                          <input class="invalid:[&:not(:focus)]:border-red-500 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-project-default"
                                 id="mijngegevens-form-tussenvoegsel"
                                 :placeholder="$t('mijn_gegevens.Tussenvoegsel')"
                                 v-model="tussenvoegsel"
                                 type="text"
                          >
                        </div>
                      </div>
                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="mijngegevens-form-naam">
                            {{ $t('mijn_gegevens.Achternaam') }} *
                          </label>
                          <input class="invalid:[&:not(:focus)]:border-red-500 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-project-default"
                                 id="mijngegevens-form-naam"
                                 v-model="naam"
                                 :placeholder="$t('mijn_gegevens.Achternaam')"
                                 type="text"
                                 :data-msg-required="lastname_required"
                                 semicolon-required
                          >
                        </div>
                      </div>

                      <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3 mb-6 md:mb-0">
                          <button
                              type="submit"
                              class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                              data-te-ripple-init
                              data-te-ripple-color="light">
                            {{ $t('registratie.verstuur') }}
                          </button>
                          <button
                              @click="hideCreateSkeletonUser"
                              type="button"
                              class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                              data-te-ripple-init
                              data-te-ripple-color="light">
                            {{ $t('mijn_gegevens.Annuleren') }}
                          </button>
                        </div>

                      </div>

                    </form>
                  </div>
                </div>
            </div>

            <!--Modal footer-->
          </div>
        </div>
      </div>

    </div>
</template>

<script type = "text/javascript">
        import Participant from './ParticipantVscComponent';
        import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
        import ErrorToast from "./ErrorToastComponent";
        import {
          Toast,
          Modal,
          initTE,
        } from "tw-elements";
        export default {
            mounted() {
                console.log('ChooseParticipantsComponent mounted.');
                let currentObj = this;
                AanmeldingModalMethods.openTweModal(currentObj,'AanmeldingParticipantsModal', initTE,Modal);
                initTE({Toast});
            },
            data() {
                let store = this.$store;
                return {
                  items: store.getters.getParticipants,
                  geslacht : '',
                  voorletters : '',
                  voornaam : '',
                  naam : '',
                  email : '',
                  tussenvoegsel: '',
                  hideCreateSkeletonUserForm : true,
                  showCreateSkeletonUserForm : false,
                  current_modal : '',
                  error_messages : [],
                  salutation_required : this.$t('mijn_gegevens.Aanhef')+' '+this.$t('mijn_gegevens.is verplicht'),
                  initials_required : this.$t('mijn_gegevens.Voorletters')+' '+this.$t('mijn_gegevens.is verplicht'),
                  firstname_required : this.$t('mijn_gegevens.Voornaam')+' '+this.$t('mijn_gegevens.is verplicht'),
                  lastname_required : this.$t('mijn_gegevens.Achternaam')+' '+this.$t('mijn_gegevens.is verplicht'),
                };
            },
            methods: {
                formSubmit(e) {
                    e.preventDefault();
                    this.closeModal();
                    let currentObj = this;
                    let store = this.$store;
                    const val = document.querySelector( 'input[name="modal-feedback-cleanliness"]:checked').value;
                    const naam_compleet  = document.querySelector( 'input[name="modal-feedback-cleanliness"]:checked').nextElementSibling.innerHTML;
                    store.dispatch('update_current_session',{klant_id:val,naam_compleet:naam_compleet});
                    this.nextPage();
                },
                createSkeletonUserSubmit(e){
                  e.preventDefault();
                  let currentObj = this;
                  let store = this.$store;
                  axios.post('/create_skeleton_user', {
                      geslacht: this.geslacht,
                      voorletters:this.voorletters,
                      voornaam:this.voornaam,
                      naam: this.naam,
                      tussenvoegsel: this.tussenvoegsel
                  })
                  .then(function (response) {
                      if(response.data.alert==='error' || response.data.result==='fail'){
                          currentObj.addError(response.data.message);
                          Toast.getInstance(document.getElementById('error-toast')).show();
                      }else{
                        currentObj.closeModal();
                        store.dispatch('update_current_session',{klant_id:response.data.klant_id,naam_compleet:response.data.naam_compleet});
                        store.dispatch('setParticipants',response.data.participants);
                        currentObj.nextPage();
                      }
                  })
                  .catch(function (error) {
                      console.log(error);
                  });
                },
                nextPage(){
                    let currentObj = this;
                    let store = this.$store;
                    if(store.getters.getIsMultipleSymposium){
                        currentObj.$parent.$router.push({ path: 'choose_event'});
                    }else{
                        currentObj.$parent.$router.push({ path: 'registration_form', query: { lang:currentObj.lang }});
                    }
                },
                showCreateSkeletonUser(e){
                  e.preventDefault();
                  this.showCreateSkeletonUserForm = true;
                  this.hideCreateSkeletonUserForm = false;
                },
                hideCreateSkeletonUser(e){
                  e.preventDefault();
                  this.showCreateSkeletonUserForm = false;
                  this.hideCreateSkeletonUserForm = true;
                },
                closeModal: function(){
                  this.currentModal.hide();
                },
                addError: function(e) {
                  this.error_messages.push(e);
                },
                clearErrors: function(){
                  this.error_messages = [];
                },
            },
            components: {
                Participant,
                ErrorToast
            }
        }

</script>
