<template>
		<button v-if="displayToelichting" type="button" class="btn btn-success btn-circle btn-xs"  @focusout="focus_out" data-container="body" data-toggle="popover" data-placement="top" :data-content="toelichtingTranslated" data-original-title="" title=""><i class="icon-question1"></i></button>
</template>

<script>
import language from '../../methods/lang';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
  import {SEMICOLON} from "../../semicolon_vanilla";

export default {
    	props: ['item','lang'],
        mounted() {
            console.log('toelichtingcomponent mounted.');
            $(document).ready(function(){
            	SEMICOLON.widget.extras();
            });
        },
        computed: {
		    toelichtingTranslated: language.toelichtingTranslated,
        },
        methods: {
        	displayToelichting: function(){
        		if(this.toelichtingTranslated==''){
        			return false;
        		}
        		return true;
        	},
        	focus_out: function(){
        		$(this.$el).popover('hide');
        	}

        }
    }
</script>