
export default{

	validate: function(obj){
        let store = obj.$store;
        if(!this.validatePrivacy(obj)){
            this.notifyError(obj,obj.$t('aanmelding.privacy_validatie_fail'));
            return false;
        }
        if(this.sessionIsEmptyAndNotTheOnlyOne(obj)){
            let session_id = store.getters.getCurrentSessieId;
            store.dispatch('removeSession',{sessie_id:session_id,override:true});
            return true;
        }
        if(!this.validatePrijs(obj)){
            this.notifyError(obj,obj.$t('aanmelding.prijs_validatie_fail'));
            return false;
        }
        return true;
    },
    validatePrijs: function(obj){
        let store = obj.$store;
        let currentSessionId = store.getters.getCurrentSessieId;
        let aanmeldEntries = store.getters.getAanmeldEntries;
        let validate = false;
        aanmeldEntries.each(function(aanmeldEntry){
            if(aanmeldEntry.sessie_id==currentSessionId&&aanmeldEntry.soort=='prijs'){
                validate = true;
            }
        })
        return validate;
    },
    validatePrivacy: function(obj){
        let store = obj.$store;
        let privacy = store.getters.mandatoryCheckboxIsChecked;
        let validate = false;
        if(privacy){
            validate = true;
        }
        return validate;
    },
    sessionIsEmptyAndNotTheOnlyOne: function(obj){
        if(this.validatePrijs(obj)){
            return false;
        }
        if(this.sessionIsTheOnlyOne(obj)){
           return false;
        }
        return true;
    },
    sessionIsTheOnlyOne: function(obj){
        let store = obj.$store;
        let sessions = store.getters.getSessions;
        if(sessions.length==1){
          return true;
        }
        return false;
    },
    notifyError: function(obj,msg){
        obj.notifyError(msg);
        // obj.elementResult.attr( 'data-notify-type', 'error' ).attr( 'data-notify-msg', msg ).html('');
        // SEMICOLON.widget.notifications( obj.elementResult );
    }
}
