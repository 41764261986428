import aanmeldingEntryModel from '../models/AanmeldingEntry';

export default {

  	createAanmeldingEntry: function (obj,value) {
		let store = obj.$store;
		let current_session_id = store.getters.getAanmelding.current_session.id;
		if(obj.aanmeldingEntry!=undefined){
			obj.aanmeldingEntry=undefined;
		}
		obj.aanmeldingEntry = new aanmeldingEntryModel;
		obj.aanmeldingEntry.aanmelding_id = store.getters.getAanmelding.aanmelding.id;
        obj.aanmeldingEntry.aanmeldformulier_id = obj.item.id;
        obj.aanmeldingEntry.value = value;
        obj.aanmeldingEntry.voucher_reserved = '';
        obj.aanmeldingEntry.klant_id = store.getters.getAanmelding.current_session.klant_id;
        obj.aanmeldingEntry.sessie_id = current_session_id;
        obj.aanmeldingEntry.volgnummer = obj.item.volgnummer;
        obj.aanmeldingEntry.soort = obj.item.soort;
        store.dispatch('createAanmeldingEntry',obj.aanmeldingEntry);
        store.dispatch('fillBill');
        return Promise.resolve("Success");
	},
	deleteAanmeldingEntry: function(obj) {
		if(obj.aanmeldingEntry==undefined){
			return false;
		}
		let store = obj.$store;
		if(obj.aanmeldingEntry.id!=null){
			store.dispatch('deleteAanmeldingEntry',obj.aanmeldingEntry);
			store.dispatch('fillBill');
			obj.aanmeldingEntry=undefined;
		}
        return Promise.resolve("Success");
	},
    updateAanmeldingEntry: function(obj) {
        obj.aanmeldingEntry = this.getAanmeldingEntry(obj);
        obj.aanmeldingEntry.value = obj.voucher;
        let store = obj.$store;
        store.dispatch('updateAanmeldingEntry',obj.aanmeldingEntry);
        this.delay(2000).then(function() {
            obj.aanmeldingEntry.value = obj.voucher;
            store.dispatch('updateAanmeldingEntry',obj.aanmeldingEntry);
            return Promise.resolve("Success");
        });
        return Promise.resolve("Success");
    },
	deleteShoppingCartItem: function(obj) {
		let store = obj.$store;
		store.dispatch('deleteAanmeldingEntry',obj.item);
		store.dispatch('fillBill');
        return Promise.resolve("Success");
	},
	aanmeldingEntryExists: function(obj) {
		let store = obj.$store;
		let current_session_id = store.getters.getAanmelding.current_session.id;
		let aameldformulier_id = obj.item.id;
		let aanmeldingEntry = store.getters.getAanmelding.sessionPropertyBag.find({aanmeldformulier_id:aameldformulier_id,sessie_id:current_session_id});
		if(aanmeldingEntry!=undefined){
			return true;
		}
		return false;
	},
	getAanmeldingEntry: function(obj) {
		let store = obj.$store;
		let current_session_id = store.getters.getAanmelding.current_session.id;
		let aameldformulier_id = obj.item.id;
		let aanmeldingEntry = store.getters.getAanmelding.sessionPropertyBag.find({aanmeldformulier_id:aameldformulier_id,sessie_id:current_session_id});
		return aanmeldingEntry;
	},
	getEntryTitel: function(obj) {
		let store = obj.$store;
		let sessie = store.getters.getSessions.find({id:obj.item.sessie_id});
		if(sessie==undefined){
			return '';
		}
		let symposium_id = parseInt(sessie.symposium_id);
		let subSymposium = store.getters.getSubSymposia.find({id:symposium_id});
		if(subSymposium==undefined){
			return '';
		}
		let aanmeldopties = subSymposium.aanmeldopties;
		let name = '';
		aanmeldopties.each(function(aanmeldoptie){
			if(aanmeldoptie.id == obj.item.aanmeldformulier_id){
				name = aanmeldoptie.naam;
			}
		})
		return name;
	},
	getAanmeldEntries: function(){
		let store = this.$store;
		return store.getters.getAanmeldEntries;
	},
    refreshPrijsEntries: function(obj){
        let store = obj.$store;
        let entries = store.getters.getAanmeldEntries;
        entries.each(function(entry){
            if(entry.soort==='prijs'||entry.soort==='prijs_6'){
                store.dispatch('fetchAanmeldingEntry',entry);
            }
        });
    },
	mandatoryCheckboxIsChecked: function(obj){
		let store = obj.$store;
		return store.getters.mandatoryCheckboxIsChecked;
	},
    delay: function(t, v) {
        return new Promise(resolve => setTimeout(resolve, t, v));
    }
}
