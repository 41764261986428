export default {
  	naamTranslated: function () {
		if(this.$i18n.locale == 'en'&&this.item.naam_en!=null){
			return this.item.naam_en;
		}
	  	return this.item.naam;
	},
	toelichtingTranslated: function () {
		if(this.$i18n.locale == 'en'&&this.item.toelichting_en!=null){
			return this.item.toelichting_en;
		}
	  	return this.item.toelichting;
	}
}