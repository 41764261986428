<template>
	<div class="list-group-item">

	    	<div class="flex flex-wrap justify-between py-1" id="mandatory_checkbox">
	    		<el-checkbox  v-model="checkedValue" v-on:click="clickCheckbox"  name="mandatoryCheckbox">
	    			<span  v-on:click="clickCheckbox" v-html="$t('aanmelding.privacy_akkoord')"></span>
	    		</el-checkbox>
	    	</div>
        <div class="flex flex-start text-project-default py-1" >
          <a class="" href="https://proveto.nl/proveto_delivery_terms.pdf" target="_blank"  >{{$t('footer.leveringsvoorwaarden')}} <i class="mdi mdi-arrow-right-circle"></i> </a>  / <a :href="privacyUrl" target="_blank" >{{$t('footer.privacy statement')}} <i class="mdi mdi-arrow-right-circle"></i></a>
        </div>
	</div>

</template>

<script>
	import language from '../../methods/lang';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
    export default {
    	props: ['lang'],
        mounted() {
            console.log('mandatory component mounted.');
            let currentObj = this;
            let store = this.$store;
            this.setChecked();
            $('div#mandatory_checkbox').on('click','.el-checkbox__input',function(event){
            	currentObj.clickCheckbox(event);
            })
        },
      computed: {
          deliveryTerms: function(){
            if(this.lang == 'en'){
              return 'https://www.proveto.nl/proveto_delivery_terms.pdf';
            }
            return 'https://www.proveto.nl/proveto_leveringsvoorwaarden.pdf';
          },
          privacyUrl: function(){
             if(this.lang == 'en'){
                return 'https://www.proveto.nl/proveto_privacy_en.pdf';
             }
             return 'https://www.proveto.nl/proveto_privacy_nl.pdf';
          }
      },
        methods: {
        	clickCheckbox: function(event){
        		let store = this.$store;
        		event.preventDefault();
        		if(this.checkedValue){
        			store.dispatch('setPrivacy',false);
        		}else{
        			store.dispatch('setPrivacy',true);
        		}
        		store.dispatch('saveAanmelding');
        	},
        	setChecked: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.mandatoryCheckboxIsChecked(this)){
        			this.checkedValue = true;
        			return;
        		}
        		this.checkedValue = false;
        	},
        	isChecked: function(){
        		return this.checkedValue;
        	}

        },
        data() {
          let store = this.$store;
	      return {
	      	checkedValue: false
	      }
	    },
		created() {
			let store = this.$store;
		    this.$store.watch(
		      (state, getters) => getters.mandatoryCheckboxIsChecked,
		      () => {
		        this.setChecked();
		      },
		    );
		    
  		}
    }
</script>
