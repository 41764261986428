import Vue from "vue";
import Vuex from "vuex";
import VueRouter from 'vue-router';
import AanmeldingObject from './AanmeldingObject';
import participantCollection from './collections/ParticipantCollection';
import SubSymposiumCollection from './collections/SubSymposiumCollection';


Vue.use(Vuex);
Vue.use(VueRouter);

const aanmelding = new AanmeldingObject();
const participants = new participantCollection();
const sub_symposia = new SubSymposiumCollection();
const video_voucher = {
    "voucher": "",
    "errorMsg": "",
    "valid": ""
};
const klant_id = '';
const locale = '';
const secondenLogger = {
                          "seconden" :[ 0 ],
                          "starttime" : 0,
                          "validationKey" : "" };
const loadedLanguages = ["nl","en"];
const systemPropertyBag = {
                            "preAanmeldingTriggered": false
                          };

const state = {
  aanmelding : aanmelding,
  participants : participants,
  sub_symposia : sub_symposia,
  klant_id : klant_id,
  secondenLogger : secondenLogger,
  loadedLanguages : loadedLanguages,
  locale : locale,
  systemPropertyBag : systemPropertyBag,
    video_voucher : video_voucher
};

sub_symposia.on('fetch', (event) => {
    state.sub_symposia.fetchAanmeldOpties();
})

const mutations = {
  ADD_NUMBER(state, payload) {
    state.numbers.push(payload);
  },
  ADD_PARTICIPANT(state, payload) {
    state.participants.add(payload);
  },
  ADD_SESSION(state,payload) {
    let currentObj = this;
    state.aanmelding.addSession(payload,currentObj);
  },
  ADD_SESSION_AFTER_REFRESH(state,payload) {
    state.aanmelding.addSessionAfterRefresh(payload);
  },
  ADD_TASK(state, payload) {
    state.tasks.push(payload);
  },
  CREATE_AANMELDING(state, payload) {
    let currentObj = this;
    state.aanmelding.createAanmelding(payload, currentObj);
  },
  CREATE_AANMELDING_ENTRY(state,payload){
    state.aanmelding.sessionPropertyBag.add(payload);
    payload.save();
    state.aanmelding.sessionPropertyBag.sort('volgnummer');
  },
  FETCH_AANMELDING(state, payload) {
  	state.aanmelding.fetch(payload);
  },
  FETCH_PARTICIPANT(state) {
    state.participants.fetch();
  },
  FETCH_SUB_SYMPOSIUM_COLLECTION(state,payload) {
    state.sub_symposia.fetch({
      url : '/sub_symposia/'+payload.symposium_id
    });
  },
  FETCH_TASKS(state) {
    state.tasks.fetch();
  },
  FETCH_AANMELDING_ENTRIES(state,payload){
    state.aanmelding.sessionPropertyBag.fetch(payload);
    state.aanmelding.sessionPropertyBag.sort('volgnummer');
  },
  FETCH_AANMELDING_ENTRY(state,payload){
    payload.fetch();
  },
  FILL_BILL(state){
    if(!state.aanmelding.aanmelding.id){
      return;
    }
    state.aanmelding.bill.id = state.aanmelding.aanmelding.id;
    state.aanmelding.bill.fetch();
  },
  FLUSH_VIDEO_SECONDS(state,payload){
    state.secondenLogger.seconden = [payload];
  },
  INIT_BLANK(state){
    state.aanmelding.initBlank();
  },
  INIT_WITH_DATA(state,payload){
    let currentObj = this;
    state.aanmelding.initWithData(currentObj,payload);
  },
  MAIL_BEFORE_PAYMENT(state){
    state.aanmelding.mailBeforePayment();
  },
  RENEW_SUB_SYMPOSIA(state,payload){
    state.sub_symposia = new SubSymposiumCollection();
    state.sub_symposia.fetch({
        url : '/sub_symposia/'+payload
    });
  },
  SAVE_AANMELDING(state){
    state.aanmelding.saveAanmelding();
  },
  SET_AANMELDOPTIES(state){
    state.aanmelding.setAanmeldopties(state.sub_symposia);
  },
  SET_AANMELDOPTIES_SERVER_DATA(state,payload){
    state.aanmelding.setAanmeldoptiesServerData(payload);
  },
  SET_KLANT_ID(state,payload){
    state.klant_id = payload.klant_id;
  },
  SET_PARTICIPANTS(state,payload){
    let obj = JSON.parse(payload);
    obj.forEach(function(participant){
      state.participants.add(participant);
    });
  },
  SET_PRIVACY(state,payload){
    state.aanmelding.setPrivacy(payload);
  },
  SET_SESSION(state,payload) {
    let currentObj = this;
    state.aanmelding.setSession(payload,currentObj);
  },
  UPDATE_CURRENT_SESSION(state,payload) {
    let currentObj = this;
    state.aanmelding.update_current_session(payload,currentObj);
  },
  PRE_AANMELDING(state,payload) {
    let currentObj = this;
    if(payload.symposium_id!='undefined'){
      state.sub_symposia.fetch({
        url : '/sub_symposia/'+payload.symposium_id
      });
      state.aanmelding.afterRefresh(payload.symposium_id,currentObj);
    }
  },
  DELETE_AANMELDING_ENTRY(state,payload){
    state.aanmelding.sessionPropertyBag.remove({'aanmeldformulier_id':payload.aanmeldformulier_id,'sessie_id':payload.sessie_id});
    payload.delete();

  },
  SET_SESSION_PROPERTYBAG(state,payload){
    payload.forEach(function(aanmelding_entry){
      state.aanmelding.sessionPropertyBag.add(payload);
    });
    state.aanmelding.sessionPropertyBag.sort('volgnummer');
  },
  SWITCH_SESSION(state,payload){
    let currentObj = this;
    state.aanmelding.set_current_session_by_id(payload,currentObj);
  },
  SET_BETALING(state,payload){
    let currentObj = this;
    state.aanmelding.aanmelding.betaling = payload;
    state.aanmelding.saveAanmelding(currentObj);
  },
  SET_DEFINITIEF(state){
    let currentObj = this;
    state.aanmelding.finishAanmelding(currentObj);
  },
  SET_AANMELDING_DEFINITIEF(state){
    state.aanmelding.aanmelding.definitief = true;
  },
  SET_LOCALE(state,payload){
    state.locale = payload;
  },
  SET_PRE_AANMELDING_TRIGGERED(state){
    state.systemPropertyBag.preAanmeldingTriggered = true;
  },
  SET_VIDEO_TOKEN(state,payload){
    state.secondenLogger.validationKey = payload;
  },
  SET_VIDEO_STARTTIME(state,payload){
    state.secondenLogger.starttime = payload;
  },
  SET_VIDEO_SECONDS(state,payload){
    if(isNaN(payload)){
      return;
    }
    let valueExists = state.secondenLogger.seconden.includes(payload);
    if(valueExists ){
      return;
    }
    state.secondenLogger.seconden.push(payload);
  },
  UPDATE_AANMELDING_ENTRY(state,payload){
    payload.save();
  },
  FLUSH_SESSIONS(state,payload){
    state.aanmelding.flushSessions();
  },
  FLUSH_KLANTID(state){
    state.klant_id = '';
  },
  FLUSH_PARTICIPANTS(state){
    state.participants = new participantCollection();
  },
  FLUSH_SUBSYMPOSIA(state){
    state.sub_symposia = new SubSymposiumCollection();
  },
  RE_INIT(state,payload){
    let currentObj = this;
    state.aanmelding.reInit(currentObj,payload);
  },
  REMOVE_SESSION(state,payload){
    let currentObj = this;
    let override = false;
    if(payload.override =! undefined){
      override = payload.override;
    }
    let sessie_id = payload.sessie_id;
    state.aanmelding.remove_session_by_id(sessie_id,currentObj,override);
  },
  DELETE_SESSION(state,payload){
    state.aanmelding.sessions.remove({'id':payload.id});
    payload.delete();
  },
  ADD_LANG(state,payload){
    state.loadedLanguages.push(payload);
  },
  INIT_SECONDEN_LOGGER(state){
      state.secondenLogger = {
                          "seconden" :[ 0 ],
                          "starttime" : 0,
                          "validationKey" : "" };
  },
    SET_VOUCHER_VALID(state,payload){
        state.video_voucher.voucher = payload.voucher;
        state.video_voucher.valid = payload.valid;
        state.video_voucher.errorMsg = payload.errorMsg;
    }


};

const actions = {
  addNumber(context, number) {
    context.commit("ADD_NUMBER", number);
  },
  createAanmelding(context, aanmelding) {
    context.commit("CREATE_AANMELDING",aanmelding);
  },
  fetchAanmelding(context,id) {
  	context.commit("FETCH_AANMELDING",id);
  },
  addParticipant(context, task) {
    context.commit("ADD_PARTICIPANT", task);
  },
  fetchParticipants(context, task) {
    context.commit("FETCH_PARTICIPANTS");
  },
  setParticipants(context,participants){
    context.commit("SET_PARTICIPANTS",participants);
  },
  setPrivacy(context,privacy){
    context.commit("SET_PRIVACY",privacy);
  },
  setSession(context,session) {
    context.commit("SET_SESSION",session);
  },
  addSession(context,session) {
    context.commit("ADD_SESSION",session);
  },
  update_current_session(context,obj) {
    context.commit("UPDATE_CURRENT_SESSION",obj);
  },
  fetchSubSymposia(context, obj) {
    context.commit("FETCH_SUB_SYMPOSIUM_COLLECTION",obj);
  },
  preAanmelding(context, obj) {
    context.commit("PRE_AANMELDING", obj);
  },
  saveAanmelding(context){
    context.commit("SAVE_AANMELDING");
  },
  setAanmeldopties(context){
    context.commit("SET_AANMELDOPTIES");
  },
  setAanmeldoptiesServerData(context,aanmeld_opties){
    context.commit("SET_AANMELDOPTIES_SERVER_DATA",aanmeld_opties);
  },
  fetchAanmeldingEntries(context){
    context.commit("FETCH_AANMELDING_ENTRIES");
  },
  fetchAanmeldingEntry(context, aanmeld_entry){
    context.commit("FETCH_AANMELDING_ENTRY", aanmeld_entry);
  },
  createAanmeldingEntry(context, aanmeld_entry){
    context.commit("CREATE_AANMELDING_ENTRY", aanmeld_entry);
  },
  updateAanmeldingEntry(context, aanmeld_entry){
    context.commit("UPDATE_AANMELDING_ENTRY", aanmeld_entry);
  },
  deleteAanmeldingEntry(context, aanmeld_entry){
    context.commit("DELETE_AANMELDING_ENTRY", aanmeld_entry);
  },
  setKlantId(context,klant_idObj){
    context.commit("SET_KLANT_ID",klant_idObj);
  },
  setSessionPropertyBag(context, aanmeld_entries){
    context.commit("SET_SESSION_PROPERTYBAG",aanmeld_entries);
  },
  addSessionAfterRefresh(context,session){
    context.commit("ADD_SESSION_AFTER_REFRESH",session);
  },
  switchSession(context,session_id){
    context.commit("SWITCH_SESSION",session_id);
  },
  fillBill(context){
    context.commit("FILL_BILL");
  },
  initSecondenLogger(context){
    context.commit("INIT_SECONDEN_LOGGER");
  },
  setAanmeldingDefinitief(context){
    context.commit('SET_AANMELDING_DEFINITIEF');
  },
  setBetaling(context,betaling){
    context.commit("SET_BETALING",betaling);
  },
  setDefinitief(context){
    context.commit("SET_DEFINITIEF");
  },
  setLocale(context,lang){
    context.commit("SET_LOCALE",lang);
  },
  setPreAanmeldingTriggered(context){
      context.commit("SET_PRE_AANMELDING_TRIGGERED");
  },
  setVideoToken(context,token){
    context.commit("SET_VIDEO_TOKEN",token);
  },
  setVideoStartTime(context,starttime){
    context.commit("SET_VIDEO_STARTTIME",starttime);
  },
  setVideoSeconds(context,seconds){
    context.commit("SET_VIDEO_SECONDS",seconds);
  },
  flushSessions(context){
    context.commit("FLUSH_SESSIONS");
  },
  flushKlantID(context){
    context.commit("FLUSH_KLANTID");
  },
  flushParticipants(context){
    context.commit("FLUSH_PARTICIPANTS");
  },
  flushSubSymposia(context){
    context.commit("FLUSH_SUBSYMPOSIA");
  },
  flushVideoSeconds(context,seconds){
    context.commit("FLUSH_VIDEO_SECONDS",seconds);
  },
  reInit(context,symposium_id){
    context.commit("RE_INIT",symposium_id);
  },
  initWithData(context,payload){
    context.commit("INIT_WITH_DATA",payload);
  },
  initBlank(context){
    context.commit("INIT_BLANK");
  },
  renewSubSymposia(context,symposium_id){
    context.commit("RENEW_SUB_SYMPOSIA",symposium_id);
  },
  removeSession(context,payload){
    context.commit("REMOVE_SESSION",payload);
  },
  deleteSession(context,session){
    context.commit("DELETE_SESSION",session);
  },
  addLang(context,lang){
    context.commit("ADD_LANG",lang);
  },
  mailBeforePayment(context){
    context.commit("MAIL_BEFORE_PAYMENT");
  },
  handleSelects(context){
    context.commit("HANDLE_SELECTS");
  },
  setVoucherValid(context,payload){
    context.commit("SET_VOUCHER_VALID",payload);
  }
};

const getters = {
  getNumbers(state) {
    return state.numbers;
  },
  getTasks(state) {
     return state.tasks;
  },
  getAanmelding(state) {
  	 return state.aanmelding;
  },
  getBetaling(state){
    return state.aanmelding.aanmelding.betaling;
  },
  getParticipants(state) {
     return state.participants;
  },
  getIsMultipleSymposium(state) {
     return state.aanmelding.aanmelding.is_multiple;
  },
  getSessions(state) {
     return state.aanmelding.sessions;
  },
  getSubSymposia(state){
     return state.sub_symposia;
  },
  getAanmeldOpties(state){
     return state.aanmelding.aanmeldOpties;
  },
  getAanmeldEntries(state){
     return state.aanmelding.sessionPropertyBag;
  },
  getLang(state){
     return state.aanmelding.aanmelding.lang;
  },
  getLocale(state){
     return state.locale;
  },
  getKlantId(state){
     return state.klant_id;
  },
  getCurrentSessieId(state){
     return state.aanmelding.getCurrentSessieId;
  },
  getCurrentSession(state){
    return state.aanmelding.current_session;
  },
  getBill(state){
    return state.aanmelding.bill;
  },
  mandatoryCheckboxIsChecked(state){
    return state.aanmelding.mandatoryCheckboxIsChecked;
  },
  getLoadedLanguages(state){
    return state.loadedLanguages;
  },
  getVideoStartTime(state){
    return state.secondenLogger.starttime;
  },
  getSecondenLogger(state){
    return state.secondenLogger;
  },
  getFactuurAllowed(state){
    // let klant = state.participants.find({id:parseInt(state.klant_id)});
    // if(klant == undefined){
    //     return true;
    // }
    // if(!klant.factuur){
    //     return false;
    // }
    return state.aanmelding.aanmelding.factuur;
  },
  getIdealAllowed(state){
    return state.aanmelding.aanmelding.ideal;
  },
  getCreditcardAllowed(state){
    return state.aanmelding.aanmelding.creditcard;
  },
  getPreAanmeldingTriggered(state){
      return state.systemPropertyBag.preAanmeldingTriggered;
  },
  getVideoVoucher(state){
      return state.video_voucher;
  }

};


export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
