<template>
	<div class="container">
    <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="RegisterUserModal"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog">
      <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]"
      >
        <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <ErrorToast :error_messages="error_messages"></ErrorToast>
            <SuccessToast :success_messages="success_messages"></SuccessToast>
            <!--Modal title-->
            <h3
                class="leading-normal text-neutral-800 dark:text-neutral-200"
                id="exampleModalScrollableLabel">
              {{ $t('aanmelding.Uw gegevens') }}
            </h3>
            <!--Close button-->
            <button
                type="button"
                @click="closeModal"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!--Modal body-->
          <div class="relative p-4">
            <div class="ajax-form" id="mijn_gegevens_div" v-html="mijn_gegevens_form"></div>
          </div>

          <!--Modal footer-->
          <div
              class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <button
                type="button"
                class="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                data-te-modal-dismiss
                data-te-ripple-init
                data-te-ripple-color="light">
              Close
            </button>
            <button
                type="button"
                class="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                data-te-ripple-init
                data-te-ripple-color="light">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
<!--		&lt;!&ndash; Modal &ndash;&gt;-->
<!--		<div class="modal1 mfp-hide fadeIn fast" id="RegisterUserModal">-->
<!--            <a href="#" class="h4 text-muted-50" onClick="$.magnificPopup.close();return false;"><i class="icon-line-circle-cross" style="position:absolute;right:5px"></i></a>-->
<!--			<div class="container">-->
<!--				<div class="row">-->
<!--					<div  class="col-sm-12" >-->
<!--                        <div class="card noborder divcenter">-->
<!--                            <div class="card-body p-4">-->
<!--                                <h3 style="margin:0">{{ $t('aanmelding.Uw gegevens') }}</h3>-->
<!--                            </div>-->
<!--                            <div id="mijn_gegevens_div" v-html="mijn_gegevens_form"></div>-->
<!--                        </div>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>				-->
	</div>
</template>

<script>
	import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
  import ErrorToast from './ErrorToastComponent';
  import SuccessToast from './SuccessToastComponent';
  import {
    Toast,
    Popover,
    Ripple,
    Modal,
    initTE,
  } from "tw-elements";
  import {SEMICOLON} from "../semicolon_vanilla";
    export default { 	
        mounted() {
            console.log('Gegevens modal mounted.');

            let currentObj = this;
                AanmeldingModalMethods.openTweModal(currentObj,'RegisterUserModal', initTE,Modal);
                axios.post('/ajax/user_registration_form', {
                       next_page: window.location.protocol+'//'+window.location.hostname+'/'+window.location.pathname,
                       symposium_id: currentObj.symposium_id
                }).then(function (response) {
                        currentObj.mijn_gegevens_form = response.data;
                        currentObj.handleEventsAfterFormReady();                   
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
        },
        props: ['symposium_id'],
        data() {
            return {
              mijn_gegevens_form:'',
              currentModal: '',
              error_messages: [],
              success_messages: [],
            };
        },
        methods: {

            waitForEl: function(selector, callback, count) {
                let currentObj = this;
                if (document.querySelector(selector)) {
                    callback();
                } else {
                    setTimeout(function() {
                      if(!count) {
                        count=0;
                      }
                      count++;
                      console.log("count: " + count);
                      if(count<10) {
                        currentObj.waitForEl(selector,callback,count);
                      } else {return;}
                    }, 1000);
                }
            },

            handleEventsAfterFormReady(){
                let selector = "#mijngegevens-form-aanmelden-submit";
                let currentObj = this;
                currentObj.waitForEl(selector, function() {
                  SEMICOLON.widget.ajaxForm(currentObj);
                  SEMICOLON.widget.extras();
                  initTE({ Popover, Ripple });
                });            
            },
            closeModal: function () {
              this.currentModal.hide();
            },
            addError: function(e) {
              this.error_messages.push(e);
            },
            clearErrors: function(){
              this.error_messages = [];
            },
            addSuccessMessage: function(msg){
              this.success_messages.push(msg);
            },
            clearSuccessMessages: function(){
              this.success_messages = [];
            },
            clearMessages: function(){
              this.clearErrors();
              this.clearSuccessMessages();
            }
        },
      components: {
        ErrorToast,
        SuccessToast
      }
    }
</script>