<template>
	<div class="container">

		<!-- Modal -->
		<div class="modal1 mfp-hide fadeIn fast" id="ModalContainer">

			<div class="card noborder divcenter" style="max-width: 500px;">
				<img :src="image.url" class="card-img-top" alt="Image">
        <a href="#" class="h4 text-muted-50" onClick="$.magnificPopup.close();return false;"><i class="icon-line-circle-cross" style="position:absolute;right:5px"></i></a>
				<div class="card-body p-4" v-html="content" >
				</div>
			</div>
		</div>					
	</div>
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    export default {
        mounted() {
            console.log('ModalComponent mounted.');
            let currentObj = this;
            $(document).ready(function(){
                AanmeldingModalMethods.openModalGeneric(currentObj,'#ModalContainer');
            });
        },
        props:['message'],
        data() {
            return {
              image: { url: '/images/aanmelding/deelnemer.jpg' }
            };
        },
        computed: {
            content(){
                return this.$i18n.t(this.message);
            }
        }
    }
</script>