<template>
  <div class="container">

    <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="passwordForgottenModal"
        tabindex="-1"
        aria-labelledby="passwordForgottenModalCenterTitle"
        aria-modal="true"
        role="dialog">
      <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]">
        <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
              class="h-72 flex flex-shrink-0 items-start justify-end content-start rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50 bg-bg-registration-deelnemer bg-center bg-no-repeat">

            <!--Close button-->
            <button
                @click="closeModal"
                type="button"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="h-6 w-6">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <!--Modal title-->

          </div>
          <div id="loader-div" class="relative flex justify-center">
            <div
                class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                    <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
            </div>
          </div>
          <div class="relative p-4">
            <h5
                class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                id="loginModalAjaxLabel">
              {{ $t('inlogscherm.login') }}
            </h5>
          </div>
          <!--Modal body-->
          <div class="relative p-4">
            <div>
              <form @submit="formSubmit">
                <div class="form-result"></div>
                <div class="form-process"></div>
                <div id="passwordForgottenModalMsg"
                     class="hidden mb-4 rounded-lg bg-danger-100 px-6 py-5 text-base text-danger-700"
                     role="alert">
                </div>
                <div class="mb-6">
                  <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ $t('passwords.Email') }}</label>
                  <input :placeholder="emailPlaceholder" type="email" v-model="username" name="username" id="username" class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/30 dark:border-zinc-700/50 dark:text-gray-300/60 dark:placeholder:text-gray-300/60" placeholder="Enter your username">
                </div>
                <div class="mb-6">
                  <p>{{ $t('passwords.hotmail_msg') }}</p>
                </div>
                <button type="submit"
                        class="btn bg-registration-button text-white w-full">{{ $t('passwords.verstuur') }}</button>
              </form>

            </div>
          </div>

          <!--Modal footer-->

        </div>
      </div>
    </div>

  </div>

</template>

<script>
import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
import {
  Modal,
  initTE,
} from "tw-elements";

export default {
  mounted() {
    console.log('passwordForgottenModal mounted.');
    let currentObj = this;
    AanmeldingModalMethods.openTweModal(currentObj,'passwordForgottenModal', initTE,Modal);
    this.hideloader();
  },
  data() {

    return {
      username: '',
      currentModal: '',
    };
  },
  computed: {
    emailPlaceholder: function(){
      return this.$t('inlogscherm.placeholder_email');
    }
  },
  methods: {
    formSubmit: function (e) {
      e.preventDefault();
      let currentObj = this;
      let store = this.$store;
      currentObj.loader();
      axios.post('/password_forgotten', {
        email: this.username
      })
          .then(function (response) {
            currentObj.hideloader();
            document.getElementById('passwordForgottenModalMsg').innerHTML = response.data.msg;
            document.getElementById('passwordForgottenModalMsg').classList.remove('hidden');
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    loader: function () {
      document.getElementById('loader-div').classList.remove('hidden');
    },
    hideloader: function () {
      document.getElementById('loader-div').classList.add('hidden');
    },
    closeModal: function () {
      this.currentModal.hide();
    }
  }
}
</script>